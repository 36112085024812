@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800');

@font-face {
  font-family: "Sofia Sans";
  src: url("../public/fonts/SofiaSansCondensed-Bold.otf");
}

@layer base {
  html {
    @apply text-canvass-950;
    font-family: Manrope, system-ui, sans-serif;
    overflow-x: hidden;
  }
}
